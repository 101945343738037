/**
 * オブジェクトを再帰的に比較し、等価か確認する
 * 配列内の順序は問わない
 */
const compareObjectRecursive = (a: any, b: any): boolean => {
  a = convertString(a)
  b = convertString(b)
  if (getObjectType(a) !== getObjectType(b)) return false
  if (getObjectType(a) === 'array') {
    if (a.length !== b.length) return false
    const a_ = Array.from(a).sort() // sort() はinplace処理のため、別配列を作成する
    const b_ = Array.from(b).sort()
    return a_.every((_, index: number) => compareObjectRecursive(a_[index], b_[index]))
  }
  if (getObjectType(a) === 'object') {
    if (Object.keys(a).length !== Object.keys(b).length) return false
    return Object.keys(a).every(key => compareObjectRecursive(a[key], b[key]))
  }
  return a === b
}

const convertString = (x: unknown): any => {
  if (typeof x === 'boolean') x = x.toString()
  if (typeof x === 'number') x = x.toString()
  return x
}

const getObjectType = (x: unknown): string => {
  if (x === null) return 'null'
  if (Array.isArray(x)) return 'array'
  return typeof x
}

export default compareObjectRecursive
