
import { defineComponent, reactive, watchEffect } from 'vue'
import { StyleName, styleNames } from '../../data/def'
import AppInput from '../atoms/AppInput.vue'

export default defineComponent({
  name: 'InputDate',
  components: {
    AppInput,
  },
  props: {
    componentId: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      default: 'ja',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    styled: {
      type: String,
      default: 'is-normal',
      validator: (val: StyleName): boolean => {
        return styleNames.includes(val)
      },
    },
    isAllDate: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup (props, context) {
    // 検索クエリとなるyyyy-mm-dd形式からiuput-box内に表示する年月日の値を再成型
    const getYear = (value: string) => {
      const year = value.substring(0, 4)
      return year === '0000' ? null : String(Number(year))
    }
    const getMonth = (value: string) => {
      const month = value.substring(5, 7)
      return month === '00' ? null : String(Number(month))
    }
    const getDay = (value: string) => {
      const day = value.substring(8)
      return day === '00' ? null : String(Number(day))
    }

    const state = reactive({
      y: props.modelValue ? getYear(props.modelValue) : null,
      m: props.modelValue ? getMonth(props.modelValue) : null,
      d: props.modelValue ? getDay(props.modelValue) : null,
    })

    watchEffect(() => {
      const y = ('0000' + (state.y || 0)).slice(-4)
      const m = ('0000' + (state.m || 0)).slice(-2)
      const d = ('0000' + (state.d || 0)).slice(-2)
      context.emit('update:modelValue', `${y}-${m}-${d}`)
    })

    return {
      state,
    }
  },
})
