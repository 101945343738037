
import { defineComponent, computed } from 'vue'
import AppButton from '@/components/atoms/AppButton.vue'
import CloserController from '@/components/molecules/CloserController.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    AppButton,
    CloserController,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue', 'clearButtonEvent'],
  setup (props, { emit }) {
    const showAll = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit('update:modelValue', val)
      },
    })
    const route = useRoute()
    const isRekion = route.meta.isRekion

    const clearForm = () => {
      emit('clearButtonEvent')
    }

    return {
      showAll,
      clearForm,
      isRekion,
    }
  },
})
