import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../public/img/RekionImage.svg'


const _withScopeId = n => (_pushScopeId("data-v-4614623f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-summary has-background-contents" }
const _hoisted_2 = { class: "collection-summary-media" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_4 = { class: "collection-summary-heading level" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "level is-mobile" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "collection-summary-content" }
const _hoisted_9 = { class: "collection-summary-description" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "collection-summary-places" }
const _hoisted_12 = { class: "level-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppImg = _resolveComponent("AppImg")!
  const _component_AppIconPlaces = _resolveComponent("AppIconPlaces")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("figure", null, [
        (_ctx.isRekion)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : (_openBlock(), _createBlock(_component_AppImg, {
              key: 1,
              src: _ctx.thumbnail(),
              alt: ""
            }, null, 8, ["src"]))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.title()), 1),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.explainURL())
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.explainURL(),
              class: "has-text-modest level is-mobile"
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('collectionForm.descriptionOfCollection')), 1)
            ], 8, _hoisted_7))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", {
          innerHTML: _ctx.isRekion ? _ctx.rekionDescription : _ctx.description()
        }, null, 8, _hoisted_10)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("ul", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.placeName, (value, key) => {
            return (_openBlock(), _createElementBlock("li", {
              key: key,
              class: "level-left is-mobile"
            }, [
              _createVNode(_component_AppIconPlaces, { type: value }, null, 8, ["type"]),
              _createVNode(_component_router_link, {
                id: `collection-summary-access-restriction-${key}`,
                to: { query: {permission_facet: [`${key}`], accessRestrictions: [`${key}`]} }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getDocCount(key)), 1)
                ]),
                _: 2
              }, 1032, ["id", "to"]),
              _createTextVNode(_toDisplayString(_ctx.$t('label.case')), 1)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}