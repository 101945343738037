
import { defineComponent, computed, reactive, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import AppButton from '@/components/atoms/AppButton.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import CheckboxList from '../molecules/CheckboxList.vue'
import { ndcList } from '../../data/ndcData'
import { identifierList } from '../../data/selector/identifierList'
import compareObjectRecursive from '@/helpers/util/compareObjectRecursive'
import { queryConvertToSortKeyAndOrder } from '@/data/SortList'
import convertToArray from '@/helpers/util/convertToArray'
import { subCollectionList } from '@/data/selector/occupationOfJapanSelector'
import ModalSheetMobile from '@/components/organisms/ModalSheetMobile.vue'
import { getSelector } from '@/data/selector/getSelector'
import StringKeyObject from '@/data/@types/StringKeyObject'
import { resetFacet, isIncludeFasetQuery, isFirstScreenTransition, createCollectionIdArray } from '@/helpers/util/queryUtils'
import DetailedSearchFooter from '@/components/molecules/DetailedSearchFooter.vue'

/**
 * コレクション詳細画面：日本占領関係資料入力フォーム
 */
export default defineComponent({
  name: 'CollectionDetailedSearchOccupationOfJapan',
  components: {
    AppButton,
    AppInput,
    CheckboxList,
    ModalSheetMobile,
    DetailedSearchFooter,
  },
  props: {
    collectionId: {
      type: String,
      required: true,
    },
    showMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, context) {
    const i18n = useI18n()
    const lang = i18n.locale
    const ndcListLocale = computed(() => ndcList[(lang.value as 'ja' | 'en')])
    const identifierListLocale = computed(() => getSelector('identifierList', identifierList, i18n.t))
    const subCollection = computed(() => getSelector('subCollectionList', subCollectionList, i18n.t))

    const accessRestrictions = [
      { value: 'internet', label: i18n.t('detailSearch.accessRestriction.internet') },
      { value: 'ooc', label: i18n.t('detailSearch.accessRestriction.ooc') },
      { value: 'inlibrary', label: i18n.t('detailSearch.accessRestriction.inlibrary') },
    ]

    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const collectionMaster = store.getters.CollectionMaster

    const makeSubCollectionList = (): any[] => {
      return createCollectionIdArray(props.collectionId, collectionMaster, route.query)
    }
    const initialQuery: StringKeyObject = {
      ...route.query,
      keyword: route.query.keyword,
      title: route.query.title,
      creator: route.query.creator,
      publisher: route.query.publisher,
      accessRestrictions: store.getters.getAccessRestrictionsCheckList,
      provenance: route.query.provenance,
      callNumber: route.query.callNumber,
      series: route.query.series,
      subject: route.query.subject,
      identifierItem: 'BIBID',
      identifier: route.query.identifier,
      includeVolumeNum: route.query.includeVolumeNum ? route.query.includeVolumeNum : 'true',
      pid: route.query.pid ? route.query.pid : null,
      subCollection: makeSubCollectionList(),
    }

    const query: StringKeyObject = reactive(initialQuery)

    const state = reactive({
      modalNdc: false,
      showAll: true,
    })

    // 1つ前の検索条件保存
    const queryCache = computed(() => store.getters.cacheQuery)

    /** ローカルのrouteキャッシュ。前回pushした内容を保持する。 */
    let queryLocalCache = {}

    /**
     * 1. 連続検索チェック(検索実行中は検索ボタンを押しても実行させない)
     * 2. 入力フォームを検索条件に変換（whereの作成）
     * 3. whereから不要な項目を削除（未入力内容など）
     * 4. 検索条件の初期化項目を設定
     * 5. route内容を比較して前回と同じだった場合はそのまま検索Action、違う場合はroute更新
     */
    const search = async () => {
      // 検索中に再度検索が呼び出された場合、実行しない
      const isSearching = store.getters.itemIsProcessing
      if (isSearching) return

      const searchMode = computed(() => store.getters.searchMode)
      // 公開範囲：チェック状態保持
      store.commit('SET_ACCESS_RESTRICTIONS', query.accessRestrictions)

      let request: StringKeyObject = {
        ...query,
        collection: [props.collectionId],
        pageNum: '0',
        fullText: true,
        pageSize: searchMode.value.pageSize || '20',
        sortKey: searchMode.value.sortKey || 'SCORE',
        displayMode: searchMode.value.displayMode || 'list',
      }
      let useQuery: StringKeyObject = {
        ...query,
        collection: [props.collectionId],
        pageNum: '0',
        fullText: true,
        pageSize: searchMode.value.pageSize || '20',
        sortKey: searchMode.value.sortKey || 'SCORE',
        displayMode: searchMode.value.displayMode || 'list',
      }
      for (var propName in useQuery) {
        if (!useQuery[propName]) {
          delete useQuery[propName]
        }
        if (Array.isArray(useQuery[propName]) && !useQuery[propName].length) {
          delete useQuery[propName]
        }
        if (!useQuery[propName] && propName !== 'includeVolumeNum') {
          delete useQuery[propName]
        }
      }
      delete useQuery.collection
      if (!useQuery.identifier) {
        delete useQuery.identifierItem
      }
      useQuery = resetFacet(useQuery)
      request = resetFacet(request)

      request.sortKey = route.query.sortKey ? queryConvertToSortKeyAndOrder(route.query.sortKey.toString()).sortKey : 'SCORE'
      request.order = route.query.sortKey ? queryConvertToSortKeyAndOrder(route.query.sortKey.toString()).order : 'DESC'

      if (isFirstScreenTransition(route.query) || isIncludeFasetQuery(route.query)) {
        // 初回遷移時（route.queryが{"pageNum":"0"}の時） or ファセットが選択されている時
        queryLocalCache = useQuery
        router.push({ path: `/collections/${props.collectionId}`, query: { ...useQuery } })
      } else {
        if (compareObjectRecursive(useQuery, queryLocalCache)) {
          await store.dispatch('collectionDetailSearch', request)
        } else {
          if (isFirstScreenTransition(queryLocalCache) && compareObjectRecursive(useQuery, queryCache.value)) {
            // 詳細画面からブラウザバックした時はqueryLocalCacheがリセットされるため
            await store.dispatch('collectionDetailSearch', request)
          } else {
            queryLocalCache = useQuery
            router.push({ path: `/collections/${props.collectionId}`, query: { ...useQuery } })
          }
        }
      }
      // 検索実行したらモーダルを閉じる
      context.emit('close')
    }

    const firstAccessRestriction = store.getters.getAccessRestrictionsCheckList

    watch(route, () => {
      query.accessRestrictions = route.query.accessRestrictions ? convertToArray(route.query.accessRestrictions) : firstAccessRestriction
      query.keyword = route.query.keyword ? route.query.keyword.toString() : ''
      query.title = route.query.title ? route.query.title.toString() : ''
      query.creator = route.query.creator ? route.query.creator.toString() : ''
      query.series = route.query.series ? route.query.series.toString() : ''
      query.subject = route.query.subject ? route.query.subject.toString() : ''
      query.publisher = route.query.publisher ? route.query.publisher.toString() : ''
      query.callNumber = route.query.callNumber ? route.query.callNumber.toString() : ''
      query.identifierItem = route.query.identifierItem ? route.query.identifierItem.toString() : 'BIBID'
      query.identifier = route.query.identifier ? route.query.identifier.toString() : ''
      query.pid = route.query.pid ? route.query.pid.toString() : ''
      query.subCollection = route.query.subCollection ? convertToArray(route.query.subCollection) : (isFirstScreenTransition(route.query) ? ['A00105', 'A00020', 'A00161', 'A00018', 'A00177', 'A00076'] : []) // ブラウザバック時（route.queryが{"pageNum":"0"}）はデフォルト値を適応
    })

    const clearForm = () => {
      query.keyword = ''
      query.title = ''
      query.creator = ''
      query.publisher = ''
      query.callNumber = ''
      query.series = ''
      query.subject = ''
      query.identifierItem = 'BIBID'
      query.identifier = ''
      query.pid = ''
      query.subCollection = ['A00105', 'A00020', 'A00161', 'A00018', 'A00177', 'A00076']
    }

    return {
      query,
      state,
      lang,
      ndcListLocale,
      identifierListLocale,
      accessRestrictions,
      search,
      clearForm,
      subCollection,
    }
  },
})
