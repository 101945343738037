export const ndcList = {
  en: [
    { value: '0', label: '0 General works' },
    { value: '1', label: '1 philosophy' },
    { value: '2', label: '2 General history' },
    { value: '3', label: '3 Social sciences' },
    { value: '4', label: '4 Natural science' },
    { value: '5', label: '5 Technology. Engineering' },
    { value: '6', label: '6 Industry and commerce' },
    { value: '7', label: '7 The arts. Fine arts' },
    { value: '8', label: '8 Language' },
    { value: '9', label: '9 Literature' },
  ],
  ja: [
    { value: '0', label: '0類 総記' },
    { value: '1', label: '1類 哲学' },
    { value: '2', label: '2類 歴史' },
    { value: '3', label: '3類 社会科学' },
    { value: '4', label: '4類 自然科学' },
    { value: '5', label: '5類 技術' },
    { value: '6', label: '6類 産業' },
    { value: '7', label: '7類 芸術' },
    { value: '8', label: '8類 言語' },
    { value: '9', label: '9類 文学' },
  ],
}

export const ndcSecondary = {
  0: [
    { value: '00', label: '00 総記' },
    { value: '01', label: '01 図書館. 図書館学' },
    { value: '02', label: '02 図書・書誌学' },
  ],
  1: [
    { value: '10', label: '10 哲学' },
    { value: '11', label: '11 哲学各論' },
  ],
}

export const ndcTertiary = {
  '00': [
    { value: '000', label: '000 総記' },
    { value: '002', label: '002 知識. 学問. 学術' },
    { value: '007', label: '007 情報科学' },
  ],
  '01': [
    { value: '010', label: '010 図書館. 図書館学' },
    { value: '011', label: '011 図書館政策. 図書館行財政' },
    { value: '012', label: '012 図書館建築. 図書館設備' },
    { value: '013', label: '013 図書館管理' },
    { value: '014', label: '014 資料の収集. 資料の整理. 資料の保管' },
    { value: '015', label: '015 図書館奉仕. 図書館活動' },
    { value: '016', label: '016 各種の図書館' },
    { value: '017', label: '017 学校図書館' },
    { value: '018', label: '018 専門図書館' },
    { value: '019', label: '019 読書. 読書法' },
  ],
  '02': [
    { value: '020', label: '020 図書・書誌学' },
    { value: '021', label: '021 著作・編集' },
    { value: '022', label: '022 写本・刊本・造本' },
    { value: '023', label: '023 出版' },
    { value: '024', label: '024 図書の販売' },
    { value: '025', label: '025 一般書誌・全国書誌' },
    { value: '026', label: '026 稀書目録・善本目録' },
    { value: '027', label: '027 特種目録' },
    { value: '028', label: '028 選定図書目録・参考図書目録' },
    { value: '029', label: '029 蔵書目録・総合目録' },
  ],
  10: [
    { value: '100', label: '100 哲学' },
    { value: '101', label: '101 哲学理論' },
    { value: '102', label: '102 哲学史' },
  ],
  11: [
    { value: '110', label: '110 哲学各論' },
  ],
}

export const ndcDic = [
  { value: '0', label: '0類 総記' },
  { value: '00', label: '00 総記' },
  { value: '000', label: '000 総記' },
  { value: '002', label: '002 知識. 学問. 学術' },
  { value: '007', label: '007 情報科学' },
  { value: '01', label: '01 図書館. 図書館学' },
  { value: '010', label: '010 図書館. 図書館学' },
  { value: '011', label: '011 図書館政策. 図書館行財政' },
  { value: '012', label: '012 図書館建築. 図書館設備' },
  { value: '013', label: '013 図書館管理' },
  { value: '014', label: '014 資料の収集. 資料の整理. 資料の保管' },
  { value: '015', label: '015 図書館奉仕. 図書館活動' },
  { value: '016', label: '016 各種の図書館' },
  { value: '017', label: '017 学校図書館' },
  { value: '018', label: '018 専門図書館' },
  { value: '019', label: '019 読書. 読書法' },
  { value: '02', label: '02 図書・書誌学' },
  { value: '020', label: '020 図書・書誌学' },
  { value: '021', label: '021 著作・編集' },
  { value: '022', label: '022 写本・刊本・造本' },
  { value: '023', label: '023 出版' },
  { value: '024', label: '024 図書の販売' },
  { value: '025', label: '025 一般書誌・全国書誌' },
  { value: '026', label: '026 稀書目録・善本目録' },
  { value: '027', label: '027 特種目録' },
  { value: '028', label: '028 選定図書目録・参考図書目録' },
  { value: '029', label: '029 蔵書目録・総合目録' },
  { value: '1', label: '1類 哲学' },
  { value: '10', label: '10 哲学' },
  { value: '100', label: '100 哲学' },
  { value: '101', label: '101 哲学理論' },
  { value: '102', label: '102 哲学史' },
  { value: '110', label: '110 哲学各論' },
  { value: '2', label: '2類 歴史' },
  { value: '3', label: '3類 社会科学' },
  { value: '4', label: '4類 自然科学' },
  { value: '5', label: '5類 技術' },
  { value: '6', label: '6類 産業' },
  { value: '7', label: '7類 芸術' },
  { value: '8', label: '8類 言語' },
  { value: '9', label: '9類 文学' },
]
