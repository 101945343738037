
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import AppImg from '@/components/atoms/AppImg.vue'
import AppIconPlaces from '@/components/atoms/AppIconPlaces.vue'
import { Bucket } from '@/data/@types/Aggregations'
import { getCollectionLevelTitle } from '@/domain/collection/collectionLevel'

export default defineComponent({
  namd: 'CollectionDetail',
  components: {
    AppImg,
    AppIconPlaces,
  },
  props: {
    collectionId: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    onMounted(() => {
      store.dispatch('collectionSummarySearch', props.collectionId)
    })
    const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale
    const route = useRoute()
    const isRekion = computed(() => route.meta.isRekion)
    const collectionInfo = computed(() => store.getters.collectionSummaryResponse)
    const getDocCount = (key: number) => {
      if (collectionInfo.value.aggregations) {
        const buckets = collectionInfo.value.aggregations.asMap.accessRestrictions.buckets
        if (buckets) {
          const bucket = Array.from<Bucket>(Object.values(buckets)).find(bk => bk.key === `${key}`)
          if (bucket) return bucket.docCount
          return 0
        }
      }
      return ''
    }
    const itemListViewCollection = computed(() => getCollectionLevelTitle(collectionInfo.value.information.content.collectionId, store, lang.value))
    return {
      searchBoxState: ref(false),
      lang,
      collectionInfo,
      placeName: isRekion.value ? { internet: 'internet', inlibrary: 'inlibrary' } : { internet: 'internet', ooc: 'ooc', inlibrary: 'inlibrary' },
      description: () => collectionInfo.value.information ? collectionInfo.value.information.content.description : '',
      title: () => collectionInfo.value.information ? itemListViewCollection.value : '',
      explainURL: () => collectionInfo.value.information ? collectionInfo.value.information.content.explanationUrl : '',
      thumbnail: () => collectionInfo.value.information ? BASE_URL + collectionInfo.value.information.content.thumbnail : '',
      getDocCount,
      isRekion,
      rekionDescription: i18n.t('label.enjoyDigitizedSound'),
    }
  },
})
