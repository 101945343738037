export const identifierList = [
  { value: 'ISBN' },
  { value: 'ISSN' },
  { value: 'PID' },
  { value: 'BIBID' },
  { value: 'JPNO' },
  { value: 'CREATORAUTHORITYID' },
  { value: 'DISSERTATION_NUMBER' },
  { value: 'PRODUCT_NUMBER' },
  { value: 'OTHER_IDENTIFIERS' },
]
export const identifierListBook = [
  { value: 'ISBN' },
  { value: 'ISSN' },
  { value: 'PID' },
  { value: 'BIBID' },
  { value: 'JPNO' },
  { value: 'CREATORAUTHORITYID' },
  { value: 'OTHER_IDENTIFIERS' },
]
export const identifierListMagazine = [
  { value: 'ISSN' },
  { value: 'PID' },
  { value: 'BIBID' },
  { value: 'JPNO' },
  { value: 'CREATORAUTHORITYID' },
]
export const identifierListOldMaterials = [
  { value: 'PID' },
  { value: 'BIBID' },
  { value: 'JPNO' },
  { value: 'CREATORAUTHORITYID' },
]
export const identifierListDoctoralDissertations = [
  { value: 'PID' },
  { value: 'BIBID' },
  { value: 'CREATORAUTHORITYID' },
  { value: 'DISSERTATION_NUMBER' },
]
export const identifierListHistoricalRecording = [
  { value: 'PID' },
  { value: 'PRODUCT_NUMBER' },
]
export const identifierListOnlinePublications = [
  { value: 'ISBN' },
  { value: 'ISSN' },
  { value: 'PID' },
  { value: 'BIBID' },
  { value: 'JPNO' },
  { value: 'OTHER_IDENTIFIERS' },
]
export const identifierListPackagedDigitalPublication = [
  { value: 'ISBN' },
  { value: 'ISSN' },
  { value: 'PID' },
  { value: 'BIBID' },
  { value: 'JPNO' },
]
export const identifierListNewspapers = [
  { value: 'ISSN' },
  { value: 'PID' },
  { value: 'BIBID' },
  { value: 'JPNO' },
  { value: 'CREATORAUTHORITYID' },
]
export const identifierListMaps = [
  { value: 'ISBN' },
  { value: 'ISSN' },
  { value: 'PID' },
  { value: 'BIBID' },
  { value: 'JPNO' },
  { value: 'CREATORAUTHORITYID' },
  { value: 'NAUTICAL_CHART_NO' },
  { value: 'GSI_TOPOGRAPHIC_MAP_NO' },
  { value: 'LOCAL_GOVERNMENT_CODE' },
  { value: 'COUNTRY_CODE' },
]
