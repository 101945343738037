import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e0b4ce8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIconPlaces = _resolveComponent("AppIconPlaces")!
  const _component_AppCheckbox = _resolveComponent("AppCheckbox")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_AppCheckbox, {
        id: `${_ctx.id}-${item.id || item.value}`,
        key: `${item.value}_${index}`,
        value: item.value,
        modelValue: _ctx.checkedItems,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedItems) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, [
            _createVNode(_component_AppIconPlaces, {
              type: item.value
            }, null, 8, ["type"]),
            _createTextVNode(" " + _toDisplayString(item.label), 1)
          ])
        ]),
        _: 2
      }, 1032, ["id", "value", "modelValue"]))
    }), 128)),
    (_ctx.hasButton)
      ? (_openBlock(), _createBlock(_component_AppButton, {
          key: 0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkedAll ? _ctx.clearAll() : _ctx.checkAll())),
          onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.checkedAll ? _ctx.clearAll() : _ctx.checkAll()), ["prevent"]), ["space","enter"])),
          id: `${_ctx.checkedAll ? 'clear' : 'check'}-all-button`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.labels[_ctx.checkedAll]), 1)
          ]),
          _: 1
        }, 8, ["id"]))
      : _createCommentVNode("", true)
  ]))
}