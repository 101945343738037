
import { defineComponent, computed, reactive, watch, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import AppButton from '@/components/atoms/AppButton.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import AppSelect from '@/components/atoms/AppSelect.vue'
import CheckboxList from '../molecules/CheckboxList.vue'
import InputDate from '../molecules/InputDate.vue'
import { eraList } from '../../data/selector/eraList'
import { identifierListOnlinePublications } from '../../data/selector/identifierList'
import compareObjectRecursive from '@/helpers/util/compareObjectRecursive'
import { queryConvertToSortKeyAndOrder } from '@/data/SortList'
import convertToArray from '@/helpers/util/convertToArray'
import { bibliographicLevelList } from '@/data/selector/bibliographicLevelList'
import ModalSheetMobile from '@/components/organisms/ModalSheetMobile.vue'
import { getSelector } from '@/data/selector/getSelector'
import StringKeyObject from '@/data/@types/StringKeyObject'
import { createCollectionIdArray, resetFacet, isIncludeFasetQuery, isFirstScreenTransition } from '@/helpers/util/queryUtils'
import DetailedSearchFooter from '@/components/molecules/DetailedSearchFooter.vue'

/**
 * コレクション詳細画面：電子書籍・電子雑誌入力フォーム
 */
export default defineComponent({
  name: 'CollectionDetailedSearchOnlinePublications',
  components: {
    AppButton,
    AppInput,
    AppSelect,
    CheckboxList,
    InputDate,
    ModalSheetMobile,
    DetailedSearchFooter,
  },
  props: {
    collectionId: {
      type: String,
      required: true,
    },
    showMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, context) {
    const i18n = useI18n()
    const lang = i18n.locale
    const identifierListLocale = computed(() => getSelector('identifierListOnlinePublications', identifierListOnlinePublications, i18n.t))
    const eraListLocale = computed(() => getSelector('eraList', eraList, i18n.t))
    const bibliographicLevel = computed(() => getSelector('bibliographicLevelList', bibliographicLevelList, i18n.t))
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const collectionMaster = store.getters.CollectionMaster

    const accessRestrictions = [
      { value: 'internet', label: i18n.t('detailSearch.accessRestriction.internet') },
      { value: 'ooc', label: i18n.t('detailSearch.accessRestriction.ooc') },
      { value: 'inlibrary', label: i18n.t('detailSearch.accessRestriction.inlibrary') },
    ]

    const makeInitialBibliographicLevel = (): any[] => {
      // 初回遷移時（route.queryが{"pageNum":"0"}）はデフォルト値を適応
      return route.query.bibliographicLevel ? convertToArray(route.query.bibliographicLevel) : (isFirstScreenTransition(route.query) ? ['0', '1', '2'] : [])
    }

    const isEqualSubCollectionQuery = (useQuery: StringKeyObject): boolean => {
      if (useQuery.subCollection == null) return false
      if (typeof useQuery.subCollection === 'string') {
        return useQuery.subCollection === route.query.subCollection
      } else {
        return useQuery.subCollection[0] === route.query.subCollection
      }
    }

    const initialQuery: StringKeyObject = {
      ...route.query,
      keyword: route.query.keyword,
      title: route.query.title,
      creator: route.query.creator,
      publisher: route.query.publisher,
      publicationName: route.query.publicationName,
      publicationVolume: route.query.publicationVolume,
      accessRestrictions: store.getters.getAccessRestrictionsCheckList,
      bibliographicLevel: makeInitialBibliographicLevel(),
      eraType: route.query.eraType ? route.query.eraType : 'AD',
      collectionEraType: route.query.collectionEraType ? route.query.collectionEraType : 'AD',
      publicationFrom: route.query.publicationFrom ? route.query.publicationFrom : null,
      publicationTo: route.query.publicationTo ? route.query.publicationTo : null,
      collectionFrom: route.query.collectionFrom ? route.query.collectionFrom : null,
      collectionTo: route.query.collectionTo ? route.query.collectionTo : null,
      identifierItem: route.query.identifierItem ? route.query.identifierItem : 'PID',
      identifier: route.query.identifier,
      subject: route.query.subject,
      description: route.query.description,
      subCollection: route.query.subCollection ? route.query.subCollection : [],
      includeVolumeNum: route.query.includeVolumeNum ? route.query.includeVolumeNum : 'true',
    }
    const query: StringKeyObject = reactive(initialQuery)
    const state = reactive({
      showAll: false,
    })

    // 1つ前の検索条件保存
    const queryCache = computed(() => store.getters.cacheQuery)

    /** ローカルのrouteキャッシュ。前回pushした内容を保持する。 */
    let queryLocalCache = {}

    /**
     * 1. 連続検索チェック(検索実行中は検索ボタンを押しても実行させない)
     * 2. 入力フォームを検索条件に変換（whereの作成）
     * 3. whereから不要な項目を削除（未入力内容など）
     * 4. 検索条件の初期化項目を設定
     * 5. route内容を比較して前回と同じだった場合はそのまま検索Action、違う場合はroute更新
     */
    const search = async () => {
      // 検索中に再度検索が呼び出された場合、実行しない
      const isSearching = store.getters.itemIsProcessing
      if (isSearching) return

      const searchMode = computed(() => store.getters.searchMode)
      // 公開範囲：チェック状態保持
      store.commit('SET_ACCESS_RESTRICTIONS', query.accessRestrictions)

      if (query.publicationFrom && query.publicationFrom.toString().length === 10) {
        query.fromYear = query.publicationFrom.toString().substr(0, 4) !== '0000' ? query.publicationFrom.toString().substr(0, 4) : ''
        query.fromMonth = query.publicationFrom.toString().substr(5, 2) !== '00' ? query.publicationFrom.toString().substr(5, 2) : ''
        query.fromDay = query.publicationFrom.toString().substr(8) !== '00' ? query.publicationFrom.toString().substr(8) : ''
      }
      if (query.publicationTo && query.publicationTo.toString().length === 10) {
        query.toYear = query.publicationTo.toString().substr(0, 4) !== '0000' ? query.publicationTo.toString().substr(0, 4) : ''
        query.toMonth = query.publicationTo.toString().substr(5, 2) !== '00' ? query.publicationTo.toString().substr(5, 2) : ''
        query.toDay = query.publicationTo.toString().substr(8) !== '00' ? query.publicationTo.toString().substr(8) : ''
      }
      if (query.collectionFrom && query.collectionFrom.toString().length === 10) {
        query.fromYear = query.collectionFrom.toString().substr(0, 4) !== '0000' ? query.collectionFrom.toString().substr(0, 4) : ''
        query.fromMonth = query.collectionFrom.toString().substr(5, 2) !== '00' ? query.collectionFrom.toString().substr(5, 2) : ''
        query.fromDay = query.collectionFrom.toString().substr(8) !== '00' ? query.collectionFrom.toString().substr(8) : ''
      }
      if (query.collectionTo && query.collectionTo.toString().length === 10) {
        query.toYear = query.collectionTo.toString().substr(0, 4) !== '0000' ? query.collectionTo.toString().substr(0, 4) : ''
        query.toMonth = query.collectionTo.toString().substr(5, 2) !== '00' ? query.collectionTo.toString().substr(5, 2) : ''
        query.toDay = query.collectionTo.toString().substr(8) !== '00' ? query.collectionTo.toString().substr(8) : ''
      }
      let request: StringKeyObject = {
        ...query,
        collection: [props.collectionId],
        pageNum: '0',
        fullText: true,
        pageSize: searchMode.value.pageSize || '20',
        sortKey: searchMode.value.sortKey || 'SCORE',
        displayMode: searchMode.value.displayMode || 'list',
      }
      let useQuery: StringKeyObject = {
        ...query,
        collection: [props.collectionId],
        pageNum: '0',
        fullText: 'true',
        pageSize: searchMode.value.pageSize || '20',
        sortKey: searchMode.value.sortKey || 'SCORE',
        displayMode: searchMode.value.displayMode || 'list',
      }
      for (var propName in useQuery) {
        if (!useQuery[propName]) {
          delete useQuery[propName]
        }
        if (Array.isArray(useQuery[propName]) && !useQuery[propName].length) {
          delete useQuery[propName]
        }
        if (!useQuery[propName] && propName !== 'includeVolumeNum') {
          delete useQuery[propName]
        }
        if (useQuery[propName] && (useQuery[propName].toString() === '0000-00-00' && ['publicationFrom', 'publicationTo'].includes(propName))) {
          delete useQuery[propName]
        }
        if (['fromYear', 'fromMonth', 'fromDay', 'toYear', 'toMonth', 'toDay'].includes(propName)) {
          delete useQuery[propName]
        }
        if (useQuery[propName] && (useQuery[propName].toString() === '0000-00-00' && ['collectionFrom', 'collectionTo'].includes(propName))) {
          delete useQuery[propName]
        }
        if (['collectionFromYear', 'collectionFromMonth', 'collectionFromDay', 'collectionToYear', 'collectionToMonth', 'collectionToDay'].includes(propName)) {
          delete useQuery[propName]
        }
      }
      if (!useQuery.publicationFrom && !useQuery.publicationTo) {
        delete useQuery.eraType
      }
      if (!useQuery.collectionFrom && !useQuery.collectionTo) {
        delete useQuery.collectionEraType
      }
      if (!useQuery.identifier) {
        delete useQuery.identifierItem
      }
      delete useQuery.collection
      useQuery = resetFacet(useQuery)
      request = resetFacet(request)

      request.sortKey = route.query.sortKey ? queryConvertToSortKeyAndOrder(route.query.sortKey.toString()).sortKey : 'SCORE'
      request.order = route.query.sortKey ? queryConvertToSortKeyAndOrder(route.query.sortKey.toString()).order : 'DESC'
      // コレクション名をコレクションIDに変換する必要がある
      request.subCollection = createCollectionIdArray(props.collectionId, collectionMaster, route.query)

      if (isFirstScreenTransition(route.query) || isIncludeFasetQuery(route.query)) {
        // 初回遷移時（route.queryが{"pageNum":"0"}の時） or ファセットが選択されている時
        queryLocalCache = useQuery
        router.push({ path: `/collections/${props.collectionId}`, query: { ...useQuery } })
      } else {
        if (compareObjectRecursive(useQuery, queryLocalCache)) {
          await store.dispatch('collectionDetailSearch', request)
        } else {
          if ((isFirstScreenTransition(queryLocalCache) && compareObjectRecursive(useQuery, queryCache.value)) || isEqualSubCollectionQuery(useQuery)) {
            // 詳細画面からブラウザバックした時はqueryLocalCacheがリセットされるため
            // subCollectionのみ特例でuseQueryとroute.queryを比較する
            await store.dispatch('collectionDetailSearch', request)
          } else {
            queryLocalCache = useQuery
            router.push({ path: `/collections/${props.collectionId}`, query: { ...useQuery } })
          }
        }
      }
      // 検索実行したらモーダルを閉じる
      context.emit('close')
    }

    const firstAccessRestriction = store.getters.getAccessRestrictionsCheckList

    const publicationResetKey = ref<number>(0)

    watch(route, () => {
      query.accessRestrictions = route.query.accessRestrictions ? convertToArray(route.query.accessRestrictions) : firstAccessRestriction
      query.keyword = route.query.keyword ? route.query.keyword.toString() : ''
      query.eraType = route.query.eraType ? route.query.eraType.toString() : 'AD'
      query.publicationFrom = route.query.publicationFrom ? route.query.publicationFrom.toString() : ''
      query.publicationTo = route.query.publicationTo ? route.query.publicationTo.toString() : ''
      publicationResetKey.value++
      query.title = route.query.title ? route.query.title.toString() : ''
      query.creator = route.query.creator ? route.query.creator.toString() : ''
      query.publisher = route.query.publisher ? route.query.publisher.toString() : ''
      query.bibliographicLevel = route.query.bibliographicLevel ? convertToArray(route.query.bibliographicLevel) : (isFirstScreenTransition(route.query) ? ['0', '1', '2'] : []) // ブラウザバック時（route.queryが{"pageNum":"0"}）はデフォルト値を適応
      query.collectionEraType = route.query.collectionEraType ? route.query.collectionEraType.toString() : 'AD'
      query.collectionFrom = route.query.collectionFrom ? route.query.collectionFrom.toString() : ''
      query.collectionTo = route.query.collectionTo ? route.query.collectionTo.toString() : ''
      query.subject = route.query.subject ? route.query.subject.toString() : ''
      query.subCollection = route.query.subCollection ? convertToArray(route.query.subCollection) : [] // undefined = 選択されていない
      query.publicationName = route.query.publicationName ? route.query.publicationName.toString() : ''
      query.publicationVolume = route.query.publicationVolume ? route.query.publicationVolume.toString() : ''
      query.identifierItem = route.query.identifierItem ? route.query.identifierItem.toString() : 'PID'
      query.identifier = route.query.identifier ? route.query.identifier.toString() : ''
      query.description = route.query.description ? route.query.description.toString() : ''
    })

    const clearForm = () => {
      query.bibliographicLevel = ['0', '1', '2']
      query.keyword = ''
      query.title = ''
      query.creator = ''
      query.publisher = ''
      query.publicationName = ''
      query.publicationVolume = ''
      query.eraType = 'AD'
      query.collectionEraType = 'AD'
      query.subject = ''
      query.publicationFrom = ''
      query.publicationTo = ''
      publicationResetKey.value++
      query.collectionFrom = ''
      query.collectionTo = ''
      query.identifierItem = 'PID'
      query.identifier = ''
      query.description = ''
      query.subCollection = []
    }
    return {
      query,
      state,
      lang,
      identifierListLocale,
      eraListLocale,
      accessRestrictions,
      bibliographicLevel,
      search,
      clearForm,
      publicationResetKey,
    }
  },
})
