import { Store } from 'vuex'
import { getParentCollectionId } from '../item/ItemListView/contentLabel'
/**
 * 受け取ったコレクションIDの階層を>つなぎで返却します。
 * @param collectionId 階層を取得したいコレクションID
 * @param store
 * @param lang
 * @returns
 */
export const getCollectionLevelTitle = (collectionId: any, store: Store<any>, lang: string): string => {
  // コレクションが未登録の場合のガード節
  if (!collectionId) return ''

  const CollectionFamily = store.getters.CollectionFamily(collectionId)

  const collectionList: Array<string> = []
  // 電子書籍・電子雑誌コレクションについては「電子書籍・電子雑誌」を表示させる
  if (CollectionFamily[0]?.collectionId === 'B00000' && CollectionFamily?.length === 1) {
    collectionList.push(CollectionFamily[0].collectionName[lang as 'en' || 'ja'])
  } else {
    // CollectionFamily[0]は最上位（デジタル化資料）のため省略
    for (let i = 1; i < CollectionFamily.length; i++) {
      collectionList.push(CollectionFamily[i].title[lang])
    }
  }

  const collectionListForDisplay = collectionList ? collectionList.join(' > ') : ''
  switch (getParentCollectionId(collectionId)) {
    // 憲政資料
    case 'A00017':
    case 'A00124': // 録音・映像関係資料 > 手稿譜
      return collectionListForDisplay
    // 官報
    case 'A00015':
      return collectionListForDisplay
    default:
      return collectionListForDisplay
  }
}
