
import { defineComponent, computed, PropType } from 'vue'
import AppCheckbox from '../atoms/AppCheckbox.vue'
import AppButton from '../atoms/AppButton.vue'
import { useI18n } from 'vue-i18n'
import AppIconPlaces from '../atoms/AppIconPlaces.vue'

interface Item {
  id: string
  value: string
  label: string
}

export default defineComponent({
  name: 'CheckboxList',
  components: {
    AppCheckbox,
    AppButton,
    AppIconPlaces,
  },
  props: {
    items: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
    modelValue: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, context) {
    const checkedItems = computed({
      get: () => props.modelValue,
      set: (val) => context.emit('update:modelValue', val),
    })
    const i18n = useI18n()

    const checkedAll = computed(() => {
      return checkedItems.value.length === props.items.length ? 1 : 0
    })

    const labels = computed(() => {
      return [i18n.t('parts.selectAll'), i18n.t('parts.clearAll')]
    })

    const checkAll = () => {
      context.emit('update:modelValue', props.items.map((item) => item.value))
    }

    const clearAll = () => {
      context.emit('update:modelValue', [])
    }

    return {
      checkedItems,
      checkedAll,
      labels,
      checkAll,
      clearAll,
    }
  },
})
