export const subCollectionList = [
  { value: 'A00004' },
  { value: 'A00005' },
  { value: 'A00006' },
  { value: 'A00007' },
  { value: 'A00008' },
]

export const classicMaterialType = [
  { value: 'JapaneseClassicalBook' },
  { value: 'ChineseClassicalBook' },
]

export const classicManuscription = [
  { value: 'Prints' },
  { value: 'Manuscript' },
]
